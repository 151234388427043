<template>
  <svg
    version="1.1"
    id="play"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :height="100 * scale"
    :width="100 * scale"
    viewBox="0 0 100 100"
    enable-background="new 0 0 100 100"
    xml:space="preserve"
  >
    <path
      class="stroke-solid"
      fill="none"
      :stroke="colour"
      d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
    C97.3,23.7,75.7,2.3,49.9,2.5"
      v-if="!hideBorder"
    />
    <path
      class="icon"
      :fill="colour"
      d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    colour: {
      default: "#3fb288",
      type: String
    },
    hideBorder: {
      default: false,
      type: Boolean
    },
    scale: {
      default: 1,
      type: Number
    }
  }
};
</script>

<style lang="scss">
.stroke-solid {
  stroke-dashoffset: 0;
  stroke-dasharray: 300;
  stroke-width: 6px;
  transform: scale(0.8);
  transform-origin: 50% 50%;
  transition: transform 200ms ease-out;
}

.icon {
  transform: scale(0.8);
  transform-origin: 50% 50%;
  transition: transform 200ms ease-out;
}

// Hover
#play:hover {
  .stroke-solid {
    transform: scale(0.95);
  }
  .icon {
    transform: scale(1.2);
  }
}

#play {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>
