var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$vuetify.breakpoint.xs ? "" : "rounded-xl",
      style:
        "background-color: white;height: 860px;position: relative;height:calc(100vh - " +
        _vm.margin +
        "px)"
    },
    [
      _c(
        "div",
        {
          staticClass: "calendly-inline-widget",
          staticStyle: { height: "100%" },
          attrs: {
            id: "vitrueCalendlyLink",
            "data-url": _vm.url,
            "data-auto-load": "false"
          }
        },
        [
          _c(
            "v-btn",
            {
              staticStyle: { transform: "translateY(30%)" },
              attrs: {
                icon: "",
                absolute: "",
                right: !_vm.$vuetify.breakpoint.smAndDown,
                left: _vm.$vuetify.breakpoint.smAndDown,
                large: _vm.$vuetify.breakpoint.mdAndUp
              },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }