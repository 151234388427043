<template>
  <v-card class="pb-12" :width="width" :height="height">
    <v-dialog
      v-model="bookAppointment"
      content-class="elevation-0 noScroll"
      max-width="1000px"
    >
      <CalendlyWidget
        v-if="bookAppointment"
        :deskAssessment="deskAssessment"
        :assessmentId="assessmentId"
        @close="bookAppointment = false"
        @update="checkIfActionButtonIsEnabled"
      />
    </v-dialog>
    <v-dialog
      v-if="showVideo"
      v-model="showVideo"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="dialog-bottom-transition"
    >
      <ExerciseVideo
        :title="heading"
        :link="link"
        :disclaimerSmallScreen="$t('wellness.v2.disclaimerSmallScreen')"
        :disclaimer="videoDisclaimer"
        @close="showVideo = false"
      />
    </v-dialog>
    <v-dialog v-if="showEmailDialog" v-model="showEmailDialog" max-width="550px"
      ><email-recommendation-dialog
        id="emailRecommendationDialog"
        v-model="showEmailDialog"
    /></v-dialog>
    <v-dialog v-model="tipsDialog">
      <v-card style="height: 75vh" class="py-2">
        <iframe
          id="tipsFrame"
          :src="link"
          style="min-height: 100%; width: 100%; border: none"
        ></iframe>
      </v-card>
    </v-dialog>
    <v-list-item>
      <v-list-item-avatar :color="headerIconColour">
        <v-icon dark>{{ headerIcon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content
        v-if="$vuetify.breakpoint.name !== 'xs' || !slideGroup"
        :class="impact ? 'pr-10' : ''"
      >
        <v-list-item-title
          id="heading"
          class="text-wrap"
          :class="[type === 'exercise' ? 'text-h6' : 'text-body-1']"
          >{{ heading }}</v-list-item-title
        >
        <v-list-item-subtitle id="subheading" class="text-wrap">{{
          subheading
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="$vuetify.breakpoint.name === 'xs' && slideGroup">
      <v-list-item-content>
        <v-list-item-title
          id="heading"
          class="text-wrap"
          :class="[type === 'exercise' ? 'text-h6' : 'text-body-1']"
          >{{ heading }}</v-list-item-title
        >
        <v-list-item-subtitle id="subheading" class="text-wrap">{{
          subheading
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-img
      v-if="imageName !== '' && $vuetify.breakpoint.smAndUp"
      :src="require(`@/assets/images/recommendations/${imageName}`)"
      height="200"
      :gradient="
        linkType === 'videoLink' && link !== ''
          ? 'rgba(0,0,0,.4), rgba(0,0,0,.6)'
          : ''
      "
      eager
      ><div v-if="coveredBy" class="banner-overlay text-center">
        <v-img
          contain
          :src="require(`@/assets/images/recommendations/banners/${coveredBy}`)"
        >
        </v-img>
      </div>
      <div
        v-if="linkType === 'videoLink' && link !== ''"
        class="video-button"
        id="videoButton"
        @click="showVideo = true"
      >
        <PlayButton />
      </div>
    </v-img>
    <v-row
      v-if="impact > 0"
      id="impactContainer"
      class="circle text-center"
      align="center"
      justify="center"
      no-gutters
    >
      <v-col>
        <p class="text-h5 mb-1 white--text reducedLineHeight">x{{ impact }}</p>
        <p class="text-subtitle ma-0 white--text reducedLineHeight">
          {{ $t("burnoutReportRecommendations.impact") }}
        </p>
      </v-col>
    </v-row>

    <v-card-text
      class="pb-0"
      id="explanation"
      style="min-height: 80px"
      v-html="explanation"
    ></v-card-text>
    <v-card-actions style="position: absolute; bottom: 0; width: 100%">
      <v-tooltip bottom :disabled="linkEnabled">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn
              id="actionButton"
              v-if="showLinkButton"
              depressed
              small
              :color="requested ? 'vitrueMidGrey' : 'selectedPrimary'"
              :class="requested ? 'secondary--text' : 'primary--text'"
              :loading="requestInProgress"
              :ripple="!requested"
              :disabled="!linkEnabled"
              @click="openLink"
              ><v-icon
                v-if="requested"
                id="requestedIcon"
                color="secondary"
                class="mr-2"
                >mdi-check-circle</v-icon
              >{{ linkMessage }}</v-btn
            >
          </div>
        </template>
        <span>{{
          $t(
            `deskAssessmentRecommendationCards.custom.ttqWel.inPersonVisit.disabledTooltip.${
              linkEnabledMessage === "AppointmentCompleted"
                ? "completedAppointment"
                : "hasActiveAppointment"
            }`
          )
        }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn id="likeButton" icon :color="heartColour" @click="saveLikes()">
        <v-icon>mdi-heart</v-icon>
      </v-btn>
    </v-card-actions>

    <v-snackbar
      v-model="showRequestSuccessMessage"
      timeout="5000"
      color="success"
    >
      {{ $t("recommendationsCardComponent.requestSuccess") }}
    </v-snackbar>
  </v-card>
</template>

<script>
import {
  createLikedItems,
  makeEquipmentRequest,
  userCanBookAppointment
} from "@/customApi";
import ExerciseVideo from "@/components/common/exercise-videos/ExerciseVideo.vue";
import PlayButton from "@/components/common/animations/PlayButton";
import EmailRecommendationDialog from "@/components/deskassessment/report/content/custom/EmailRecommendationDialog.vue";
import { LinkTypes } from "@/services/recommendations/link-types.js";
import CalendlyWidget from "./CalendlyWidget.vue";

export default {
  name: "recommendations-card",
  components: {
    ExerciseVideo,
    PlayButton,
    EmailRecommendationDialog,
    CalendlyWidget
  },
  props: {
    id: String,
    assessmentId: String,
    heading: String,
    subheading: String,
    imageName: {
      type: String,
      default: ""
    },
    explanation: String,
    type: String,
    link: {
      type: String,
      default: ""
    },
    route: {
      type: String,
      default: ""
    },
    previouslyLiked: {
      type: Boolean,
      default: false
    },
    previouslyRequested: {
      type: Boolean,
      default: false
    },
    coveredBy: String,
    width: String,
    height: String,
    impact: Number,
    slideGroup: Boolean,
    videoDisclaimer: String,
    linkType: String,
    deskAssessment: Object
  },
  created() {
    this.checkIfActionButtonIsEnabled();
  },
  data() {
    return {
      dialog: false,
      liked: this.previouslyLiked,
      showVideo: false,
      showEmailDialog: false,
      tipsDialog: false,
      requested: this.previouslyRequested,
      requestInProgress: false,
      showRequestSuccessMessage: false,
      bookAppointment: false,
      linkEnabled: this.linkType !== LinkTypes.APPOINTMENT,
      linkEnabledMessage: ""
    };
  },
  watch: {
    previouslyLiked(newVal) {
      this.liked = newVal;
    },
    previouslyRequested(newVal) {
      this.requested = newVal;
    }
  },
  computed: {
    showLinkButton() {
      if (this.linkType === LinkTypes.VIDEO) {
        return this.link !== "" && this.$vuetify.breakpoint.smAndDown;
      }

      return true;
    },
    heartColour() {
      return this.liked ? "red" : "grey";
    },
    headerIconColour() {
      switch (this.type) {
        case "equipment":
        case "pregnancy":
          return "primary";
        case "customProfessional":
        case "exercise":
        case "customExercise":
        case "wellness":
          return "secondary";
        case "burnout":
          return "accent";
        default:
          return "primary";
      }
    },
    headerIcon() {
      switch (this.type) {
        case "equipment":
          return "mdi-cart-outline";
        case "habit":
          return "mdi-clock-outline";
        case "exercise":
        case "customExercise":
          return "mdi-dumbbell";
        case "burnout":
          return "mdi-handshake";
        case "pregnancy":
          return "mdi-account-child-outline";
        case "wellness":
          return "mdi-spa-outline";
        default:
          return "laptop";
      }
    },
    linkMessage() {
      return this.linkBehaviour[this.linkType]?.message;
    },
    linkBehaviour() {
      return {
        [LinkTypes.VIDEO]: {
          message: this.$t("recommendationsCardComponent.playVideo"),
          onClick: async () => {
            this.showVideo = true;
          }
        },
        [LinkTypes.INTERNAL]: {
          message: this.$t("recommendationsCardComponent.goToRoute"),
          onClick: async () => {
            this.$router.push({
              name: this.route
            });
          }
        },
        [LinkTypes.EMAIL]: {
          message: this.$t("recommendationsCardComponent.contactUs"),
          onClick: async () => {
            window.open(`mailto:${this.link}`, "emailWindow");
          }
        },
        [LinkTypes.REQUEST]: {
          message: this.requested
            ? this.$t("recommendationsCardComponent.requested")
            : this.$t("recommendationsCardComponent.requestNow"),
          onClick: async () => {
            await this.requestItem();
          }
        },
        [LinkTypes.EMAILREQUEST]: {
          message: this.$t("recommendationsCardComponent.howToRequest"),
          onClick: async () => {
            this.showEmailDialog = true;
          }
        },
        [LinkTypes.PURCHASE]: {
          message: this.$t("recommendationsCardComponent.buy"),
          onClick: async () => {
            window.open(this.link, "_blank");
          }
        },
        [LinkTypes.REGULAR]: {
          message: this.$t("recommendationsCardComponent.learnMore"),
          onClick: async () => {
            window.open(this.link, "_blank");
          }
        },
        [LinkTypes.APPOINTMENT]: {
          message: this.$t("recommendationsCardComponent.scheduleAppointment"),
          onClick: () => {
            this.bookAppointment = true;
          },
          canClick: async () => {
            try {
              var response = await userCanBookAppointment(this.assessmentId);
              return response;
            } catch (err) {
              return false;
            }
          }
        },
        [LinkTypes.TIPS]: {
          message: this.$t("recommendationsCardComponent.tips"),
          onClick: async () => {
            this.tipsDialog = true;
          }
        }
      };
    }
  },
  methods: {
    async openLink() {
      this.$gtag.event(
        `Link button clicked for recommendation - ${this.id} of type ${this.linkType}`,
        {
          event_category: "Desk Assessment - Report"
        }
      );
      this.$mixpanel.track("Recommendation click", {
        page: this.$route.path,
        type: this.linkType,
        id: this.id
      });
      await this.linkBehaviour[this.linkType]?.onClick();
    },
    async saveLikes() {
      this.liked = !this.liked;

      this.$gtag.event("Like button clicked for recommendation", {
        event_category: "Desk Assessment - Report"
      });

      let likedItemsDto = {
        AssessmentId: this.assessmentId,
        Item: this.id,
        Liked: this.liked
      };
      try {
        await createLikedItems(likedItemsDto);
      } catch (err) {
        this.liked = !this.liked;
      }
    },
    async requestItem() {
      if (this.requested) {
        return;
      }

      try {
        this.requestInProgress = true;
        await makeEquipmentRequest(this.id, this.assessmentId);
        this.requested = true;
        this.showRequestSuccessMessage = true;
      } catch (ex) {
      } finally {
        this.requestInProgress = false;
      }
    },
    async checkIfActionButtonIsEnabled() {
      let canClick = this.linkBehaviour[this.linkType]?.canClick;
      if (canClick) {
        var response = await canClick();
        this.linkEnabled = response.canBook;
        this.linkEnabledMessage = response.message;
      }
    }
  }
};
</script>

<style scoped>
.circle {
  position: absolute;
  top: -5%;
  right: -3%;
  z-index: 4;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: var(--v-vitruePaleBlue-base);
}

.reducedLineHeight {
  line-height: 80%;
}
.banner-overlay {
  position: absolute;
  top: 5%;
  left: -15%;
  z-index: 10;
  width: 60%;
  background: white;
  transform: rotate(315deg);
}

.video-button {
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 50%;
}
</style>
