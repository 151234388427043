<template>
  <v-card
    class="text-center px-6"
    :class="$vuetify.breakpoint.mdAndDown ? 'py-5' : 'py-12'"
  >
    <v-btn
      @click="$emit('input', false)"
      class="close-button"
      icon
      color="black"
    >
      <v-icon>clear</v-icon>
    </v-btn>
    <v-row
      justify="center"
      style="width: 100%"
      :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : 'mb-8'"
      ><v-col cols="auto"
        ><v-img
          :max-width="$vuetify.breakpoint.mdAndDown ? '140px' : '270px'"
          contain
          :src="require('@/assets/images/sendEmailDialogHeader.png')" /></v-col
    ></v-row>

    <p
      class="primary--text"
      :class="$vuetify.breakpoint.mdAndDown ? 'text-h6' : 'text-h4'"
    >
      {{
        $t("deskAssessment.results.recommendationsContent.emailDialog.title")
      }}
    </p>
    <p :class="$vuetify.breakpoint.mdAndDown ? 'text-body-2' : 'text-body-1'">
      {{ $t("deskAssessment.results.recommendationsContent.emailDialog.info") }}
    </p>
    <i18n
      :class="$vuetify.breakpoint.mdAndDown ? 'text-body-2' : 'text-body-1'"
      path="deskAssessment.results.recommendationsContent.emailDialog.instruction"
    >
      <template v-slot:requestAction>
        <span class="font-weight-bold">{{
          $t("deskAssessment.results.recommendationsContent.emailDialog.action")
        }}</span>
      </template>
    </i18n>
    <p
      class="mt-4"
      :class="$vuetify.breakpoint.mdAndDown ? 'text-body-2' : 'text-body-1'"
    >
      {{
        $t(
          "deskAssessment.results.recommendationsContent.emailDialog.personalInfo"
        )
      }}
    </p>
    <v-spacer />
    <v-btn
      :class="$vuetify.breakpoint.mdAndDown ? 'mt-2' : 'mt-4'"
      @click="$emit('input', false)"
      rounded
      outlined
      min-width="170px"
      color="primary"
      >{{ $t("buttons.close") }}</v-btn
    >
  </v-card>
</template>

<script>
export default {
  name: "EmailRecommendationDialog"
};
</script>
