var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pb-12", attrs: { width: _vm.width, height: _vm.height } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "elevation-0 noScroll",
            "max-width": "1000px"
          },
          model: {
            value: _vm.bookAppointment,
            callback: function($$v) {
              _vm.bookAppointment = $$v
            },
            expression: "bookAppointment"
          }
        },
        [
          _vm.bookAppointment
            ? _c("CalendlyWidget", {
                attrs: {
                  deskAssessment: _vm.deskAssessment,
                  assessmentId: _vm.assessmentId
                },
                on: {
                  close: function($event) {
                    _vm.bookAppointment = false
                  },
                  update: _vm.checkIfActionButtonIsEnabled
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showVideo
        ? _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: _vm.$vuetify.breakpoint.xs,
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.showVideo,
                callback: function($$v) {
                  _vm.showVideo = $$v
                },
                expression: "showVideo"
              }
            },
            [
              _c("ExerciseVideo", {
                attrs: {
                  title: _vm.heading,
                  link: _vm.link,
                  disclaimerSmallScreen: _vm.$t(
                    "wellness.v2.disclaimerSmallScreen"
                  ),
                  disclaimer: _vm.videoDisclaimer
                },
                on: {
                  close: function($event) {
                    _vm.showVideo = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showEmailDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "550px" },
              model: {
                value: _vm.showEmailDialog,
                callback: function($$v) {
                  _vm.showEmailDialog = $$v
                },
                expression: "showEmailDialog"
              }
            },
            [
              _c("email-recommendation-dialog", {
                attrs: { id: "emailRecommendationDialog" },
                model: {
                  value: _vm.showEmailDialog,
                  callback: function($$v) {
                    _vm.showEmailDialog = $$v
                  },
                  expression: "showEmailDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.tipsDialog,
            callback: function($$v) {
              _vm.tipsDialog = $$v
            },
            expression: "tipsDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "py-2", staticStyle: { height: "75vh" } },
            [
              _c("iframe", {
                staticStyle: {
                  "min-height": "100%",
                  width: "100%",
                  border: "none"
                },
                attrs: { id: "tipsFrame", src: _vm.link }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-avatar",
            { attrs: { color: _vm.headerIconColour } },
            [
              _c("v-icon", { attrs: { dark: "" } }, [
                _vm._v(_vm._s(_vm.headerIcon))
              ])
            ],
            1
          ),
          _vm.$vuetify.breakpoint.name !== "xs" || !_vm.slideGroup
            ? _c(
                "v-list-item-content",
                { class: _vm.impact ? "pr-10" : "" },
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "text-wrap",
                      class: [
                        _vm.type === "exercise" ? "text-h6" : "text-body-1"
                      ],
                      attrs: { id: "heading" }
                    },
                    [_vm._v(_vm._s(_vm.heading))]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "text-wrap", attrs: { id: "subheading" } },
                    [_vm._v(_vm._s(_vm.subheading))]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.$vuetify.breakpoint.name === "xs" && _vm.slideGroup
        ? _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "text-wrap",
                      class: [
                        _vm.type === "exercise" ? "text-h6" : "text-body-1"
                      ],
                      attrs: { id: "heading" }
                    },
                    [_vm._v(_vm._s(_vm.heading))]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "text-wrap", attrs: { id: "subheading" } },
                    [_vm._v(_vm._s(_vm.subheading))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.imageName !== "" && _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-img",
            {
              attrs: {
                src: require("@/assets/images/recommendations/" +
                  _vm.imageName),
                height: "200",
                gradient:
                  _vm.linkType === "videoLink" && _vm.link !== ""
                    ? "rgba(0,0,0,.4), rgba(0,0,0,.6)"
                    : "",
                eager: ""
              }
            },
            [
              _vm.coveredBy
                ? _c(
                    "div",
                    { staticClass: "banner-overlay text-center" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/recommendations/banners/" +
                            _vm.coveredBy)
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.linkType === "videoLink" && _vm.link !== ""
                ? _c(
                    "div",
                    {
                      staticClass: "video-button",
                      attrs: { id: "videoButton" },
                      on: {
                        click: function($event) {
                          _vm.showVideo = true
                        }
                      }
                    },
                    [_c("PlayButton")],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm.impact > 0
        ? _c(
            "v-row",
            {
              staticClass: "circle text-center",
              attrs: {
                id: "impactContainer",
                align: "center",
                justify: "center",
                "no-gutters": ""
              }
            },
            [
              _c("v-col", [
                _c(
                  "p",
                  { staticClass: "text-h5 mb-1 white--text reducedLineHeight" },
                  [_vm._v("x" + _vm._s(_vm.impact))]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-subtitle ma-0 white--text reducedLineHeight"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("burnoutReportRecommendations.impact")) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c("v-card-text", {
        staticClass: "pb-0",
        staticStyle: { "min-height": "80px" },
        attrs: { id: "explanation" },
        domProps: { innerHTML: _vm._s(_vm.explanation) }
      }),
      _c(
        "v-card-actions",
        { staticStyle: { position: "absolute", bottom: "0", width: "100%" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", disabled: _vm.linkEnabled },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "div",
                        _vm._g(_vm._b({}, "div", attrs, false), on),
                        [
                          _vm.showLinkButton
                            ? _c(
                                "v-btn",
                                {
                                  class: _vm.requested
                                    ? "secondary--text"
                                    : "primary--text",
                                  attrs: {
                                    id: "actionButton",
                                    depressed: "",
                                    small: "",
                                    color: _vm.requested
                                      ? "vitrueMidGrey"
                                      : "selectedPrimary",
                                    loading: _vm.requestInProgress,
                                    ripple: !_vm.requested,
                                    disabled: !_vm.linkEnabled
                                  },
                                  on: { click: _vm.openLink }
                                },
                                [
                                  _vm.requested
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            id: "requestedIcon",
                                            color: "secondary"
                                          }
                                        },
                                        [_vm._v("mdi-check-circle")]
                                      )
                                    : _vm._e(),
                                  _vm._v(_vm._s(_vm.linkMessage))
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "deskAssessmentRecommendationCards.custom.ttqWel.inPersonVisit.disabledTooltip." +
                        (_vm.linkEnabledMessage === "AppointmentCompleted"
                          ? "completedAppointment"
                          : "hasActiveAppointment")
                    )
                  )
                )
              ])
            ]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { id: "likeButton", icon: "", color: _vm.heartColour },
              on: {
                click: function($event) {
                  return _vm.saveLikes()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-heart")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "success" },
          model: {
            value: _vm.showRequestSuccessMessage,
            callback: function($$v) {
              _vm.showRequestSuccessMessage = $$v
            },
            expression: "showRequestSuccessMessage"
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("recommendationsCardComponent.requestSuccess")) +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }