var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "play",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        height: 100 * _vm.scale,
        width: 100 * _vm.scale,
        viewBox: "0 0 100 100",
        "enable-background": "new 0 0 100 100",
        "xml:space": "preserve"
      }
    },
    [
      !_vm.hideBorder
        ? _c("path", {
            staticClass: "stroke-solid",
            attrs: {
              fill: "none",
              stroke: _vm.colour,
              d:
                "M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7\n  C97.3,23.7,75.7,2.3,49.9,2.5"
            }
          })
        : _vm._e(),
      _c("path", {
        staticClass: "icon",
        attrs: {
          fill: _vm.colour,
          d:
            "M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }