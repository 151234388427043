<template>
  <div
    :style="`background-color: white;height: 860px;position: relative;height:calc(100vh - ${margin}px)`"
    :class="$vuetify.breakpoint.xs ? '' : 'rounded-xl'"
  >
    <div
      id="vitrueCalendlyLink"
      class="calendly-inline-widget"
      :data-url="url"
      data-auto-load="false"
      style="height: 100%"
    >
      <v-btn
        icon
        absolute
        :right="!$vuetify.breakpoint.smAndDown"
        :left="$vuetify.breakpoint.smAndDown"
        @click="$emit('close')"
        :large="$vuetify.breakpoint.mdAndUp"
        style="transform: translateY(30%)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import { mapGetters } from "vuex";
import { addAppointment } from "@/customApi";

export default {
  name: "CalendlyWidget",
  props: {
    deskAssessment: Object,
    assessmentId: String
  },
  data() {
    return {
      widgetUrl: "https://assets.calendly.com/assets/external/widget.js",
      scriptId: "calendlyWidget",
      appointmentScheduled: false
    };
  },
  created() {
    this.addListener();
    var script = this.addScript();
    script.onload = this.createWidget;
  },
  beforeDestroy() {
    this.removeScript();
  },
  computed: {
    ...mapGetters(["userEmail"]),
    url() {
      return process.env.NODE_ENV === "production"
        ? "https://calendly.com/vidaassessment/30min?"
        : "https://calendly.com/chris-3367/30min?month=2023-01";
    },
    margin() {
      let highZoom = window.devicePixelRatio > 1;
      if (this.$vuetify.breakpoint.lgAndUp && !highZoom) {
        return 220;
      }
      return this.$vuetify.breakpoint.mdAndUp ? 100 : 50;
    },
    score() {
      if (!this.deskAssessment) {
        return "";
      }
      if (this.deskAssessment.savedScore !== undefined) {
        return this.deskAssessment.savedScore;
      }
      return DeskAssessmentUtil.getOverallScore(this.deskAssessment);
    }
  },
  methods: {
    removeScript() {
      var script = document.getElementById(this.scriptId);
      script.parentNode.removeChild(script);
    },
    addScript() {
      const script = document.createElement("script");
      script.id = this.scriptId;
      script.setAttribute("src", this.widgetUrl);
      document.head.appendChild(script);
      return script;
    },
    createWidget() {
      Calendly.initInlineWidget({
        url: this.url,
        parentElement: document.getElementById("vitrueCalendlyLink"),
        prefill: {
          email: this.userEmail ?? "",
          customAnswers: { a1: this.score }
        },
        utm: {}
      });
    },
    addListener() {
      window.addEventListener("message", e => {
        if (this.isCalendlyEvent(e)) {
          this.handleCalendlyEvent(e);
        }
      });
    },
    isCalendlyEvent(e) {
      return (
        e.origin === "https://calendly.com" &&
        e.data.event &&
        e.data.event.indexOf("calendly.") === 0
      );
    },
    handleCalendlyEvent(e) {
      if (
        e.data.event === "calendly.event_scheduled" &&
        !this.appointmentScheduled
      ) {
        this.appointmentScheduled = true;
        this.logAppointment();
      }
    },
    async logAppointment() {
      try {
        await addAppointment(this.assessmentId);
      } catch (e) {
      } finally {
        this.$emit("update");
      }
    }
  }
};
</script>
