var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "text-center px-6",
      class: _vm.$vuetify.breakpoint.mdAndDown ? "py-5" : "py-12"
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndDown ? "mb-2" : "mb-8",
          staticStyle: { width: "100%" },
          attrs: { justify: "center" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-img", {
                attrs: {
                  "max-width": _vm.$vuetify.breakpoint.mdAndDown
                    ? "140px"
                    : "270px",
                  contain: "",
                  src: require("@/assets/images/sendEmailDialogHeader.png")
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "p",
        {
          staticClass: "primary--text",
          class: _vm.$vuetify.breakpoint.mdAndDown ? "text-h6" : "text-h4"
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "deskAssessment.results.recommendationsContent.emailDialog.title"
                )
              ) +
              " "
          )
        ]
      ),
      _c(
        "p",
        {
          class: _vm.$vuetify.breakpoint.mdAndDown
            ? "text-body-2"
            : "text-body-1"
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "deskAssessment.results.recommendationsContent.emailDialog.info"
                )
              ) +
              " "
          )
        ]
      ),
      _c("i18n", {
        class: _vm.$vuetify.breakpoint.mdAndDown
          ? "text-body-2"
          : "text-body-1",
        attrs: {
          path:
            "deskAssessment.results.recommendationsContent.emailDialog.instruction"
        },
        scopedSlots: _vm._u([
          {
            key: "requestAction",
            fn: function() {
              return [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "deskAssessment.results.recommendationsContent.emailDialog.action"
                      )
                    )
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "p",
        {
          staticClass: "mt-4",
          class: _vm.$vuetify.breakpoint.mdAndDown
            ? "text-body-2"
            : "text-body-1"
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "deskAssessment.results.recommendationsContent.emailDialog.personalInfo"
                )
              ) +
              " "
          )
        ]
      ),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          class: _vm.$vuetify.breakpoint.mdAndDown ? "mt-2" : "mt-4",
          attrs: {
            rounded: "",
            outlined: "",
            "min-width": "170px",
            color: "primary"
          },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("buttons.close")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }